<template>
  <div>
    <HeaderContent label="Penggalangan Dana" :list="crumbs" />
    <div v-if="isLoadingDonation" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div :class="d['detail__container']">
      <MediaContent :item="donation" />
      <Amount :item="donation" />
      <Amount-Items
        v-if="donation.isDonationItem"
        :item="donation"
        @updateAmountItems="handleDetail()"
      ></Amount-Items>
      <div :class="d['detail__line']"></div>
      <Activity />
    </div>
  </div>
</template>

<script>
import HeaderContent from "@/containers/HeaderContent";
import { mapActions } from "vuex";
import MediaContent from "./header.vue";
import Amount from "./amount.vue";
import AmountItems from "./amountItems.vue";
import Activity from "./activity.vue";
import mixins from "@/mixins/global.js";

export default {
  mixins: [mixins],
  components: {
    HeaderContent,
    MediaContent,
    Amount,
    AmountItems,
    Activity,
  },
  data() {
    return {
      crumbs: [
        {
          text: "List Penggalangan Dana",
          disabled: false,
        },
        {
          text: "Penggalangan Dana",
          disabled: true,
        },
      ],
      donation: {
        image: "",
        video: "",
        province: "",
        title: "",
        description: "",
        recipientName: "",
        initiator: "",
        targetAmount: 0,
        amountCollected: 0,
        amountWithdraw: 0,
        amountItemCollected: 0,
        amountItemWithdraw: 0,
        createAt: "",
        expiredAt: "",
        vod: {},
      },
      isLoadingDonation: false,
    };
  },
  mounted() {
    this.handleDetail();
  },
  methods: {
    ...mapActions({
      fetchDetailDonation: "donation/fetchDetailDonation",
    }),
    handleDetail() {
      const id = this.$route.params.id;
      this.isLoadingDonation = true;
      return this.fetchDetailDonation(id)
        .then((response) => {
          this.isLoadingDonation = false;
          console.log("detail", response);
          const medias = response.medias;
          let image, video, vod;
          medias.forEach((media) => {
            if (media.type === "image") {
              this.image = media.url;
              image = media.url;
            } else {
              this.video = media.url;
              const url = this.vodUrl(media);
              video = url;
              vod = media;
            }
          });
          this.donation = Object.assign({}, this.donation, {
            province: response.province.name,
            initiator: response.initiator.name,
            recipientName: response.recipientName,
            title: response.title,
            description: response.description,
            image,
            video,
            targetAmount: response.targetAmount,
            amountCollected: response.amountCollected,
            amountWithdraw: response.amountWithdraw,
            amountItemCollected: response.amountItemCollected,
            amountItemWithdraw: response.amountItemWithdraw,
            createAt: response.createAt,
            expiredAt: response.expiredAt,
            status: response.status,
            isDonationItem: response.isDonationItem,
            vod,
          });
        })
        .catch((err) => {
          this.isLoadingDonation = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" module="d" src="../donation.scss"></style>
