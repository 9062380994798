import { render, staticRenderFns } from "./header.vue?vue&type=template&id=d6923c68"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"
import style0 from "../donation.scss?vue&type=style&index=0&prod&lang=scss&module=d&external"
import style1 from "./header.vue?vue&type=style&index=1&id=d6923c68&prod&lang=css"




function injectStyles (context) {
  
  this["d"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports