var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      _vm.cur.container,
      
    ]},[_c('label',{class:_vm.cur.label},[_vm._v(_vm._s(_vm.label))]),_c('div',{class:[
        _vm.cur.input, 
        { 
          'input-disable' : _vm.isDisable}]},[_c('span',[_vm._v("Rp")]),_c('input',{ref:"inputRef",attrs:{"type":"text","disabled":_vm.isDisable},domProps:{"value":_vm.value}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }