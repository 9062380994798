<template>
  <div :class="d['detail__amount']">
    <div>
      <div :class="d['amount-colect']">
        <div :class="d['amount-title']">Nilai Barang Terkumpul</div>
        <div :class="d['amount-balance']">
          Rp {{ item.amountItemCollected.toLocaleString("id") }}
        </div>
      </div>
      <div :class="d['amount-list']" style="height: 220px; overflow: auto">
        <div v-if="isLoadingItems" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <div class="d-flex flex-column" style="gap: 12px">
          <div
            v-for="item in items"
            :key="item.id"
            class="d-flex"
            style="
              height: 60px;
              background: white;
              border-radius: 4px;
              box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
              overflow: hidden;
            "
          >
            <div>
              <img :src="item.productImage" alt="" width="60" height="60" />
            </div>
            <div
              class="d-flex flex-column justify-space-between px-2 pt-1 pb-2"
              style="width: 100%"
            >
              <span>{{ item.productName }}</span>
              <div class="d-flex flex-column" style="gap: 2px">
                <div class="d-flex justify-space-between">
                  <span style="font-size: 11px">Terkumpul</span>
                  <span style="font-size: 11px"
                    >{{ item.totalItemCollected }} / {{ item.targetQty }}</span
                  >
                </div>
                <v-progress-linear
                  :value="(item.totalItemCollected / item.targetQty) * 100"
                  :color="
                    item.totalItemCollected >= item.targetQty
                      ? 'success'
                      : 'primary'
                  "
                  background-color="#EEEEEE"
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div :class="d['amount-withdraw']">
        <div :class="d['amount-title']">Penarikan Donasi Barang</div>
        <div class="d-flex justify-space-between" :class="d['amount-balance']">
          Rp {{ item.amountItemWithdraw.toLocaleString("id") }}
          <v-btn
            outlined
            color="white"
            width="125"
            class="text-capitalize"
            style="font-size: 12px; font-weight: 700; letter-spacing: 0px"
            @click="isDialogPostHistory = true"
            >Catat Penarikan</v-btn
          >
        </div>
      </div>

      <div :class="d['amount-list']" style="height: 220px; overflow: auto">
        <div v-if="isLoadingHistory" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <div class="d-flex flex-column" style="gap: 8px">
          <div v-for="(h, idx) in history" :key="idx">
            <div class="d-flex">
              <div>{{ formated(h.withdrawAt) }}</div>
              <div style="margin-left: auto">
                Rp {{ h.amountWithdraw.toLocaleString("id") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-dialog
        v-model="isDialogPostHistory"
        :width="isSuccessPostHistory ? '221' : '343'"
      >
        <v-card
          v-if="isSuccessPostHistory"
          class="d-flex flex-column align-center py-4 px-3"
          style="gap: 12px"
        >
          <v-icon size="30" color="success">fas fa-check-circle</v-icon>
          <span style="font-size: 12px; font-weight: 500"
            >Penarikan Donasi Barang Disimpan</span
          >
        </v-card>
        <v-card v-else>
          <section class="pa-4">
            <span style="font-size: 14px; font-weight: 500"
              >Catat Penarikan Donasi Barang</span
            >
          </section>
          <v-divider></v-divider>
          <section class="d-flex flex-column pa-4" style="gap: 12px">
            <k-date
              title="Tanggal Penarikan"
              placeholder="dd/mm/yy"
              :minDate="minDate"
              :maxDate="maxDate"
              :value="payloadHistory.withdrawAt"
              @epochDate="getEpoch"
            />

            <CurrencyInput
              label="Nominal Penarikan"
              v-model="payloadHistory.amountWithdraw"
              :options="{
                currency: 'IDR',
                locale: 'id',
                currencyDisplay: 'hidden',
                hideGroupingSeparatorOnFocus: false,
                valueRange: {
                  min: 1,
                  max: item.amountItemCollected - item.amountItemWithdraw,
                },
              }"
            />
          </section>
          <v-divider></v-divider>
          <section class="d-flex pa-4" style="gap: 12px">
            <v-btn
              depressed
              color="#F9F9F9"
              class="text-capitalize"
              style="
                width: 120px;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0px;
                border-radius: 8px;
                border: 1px solid #eeeeee;
              "
              @click="isDialogPostHistory = false"
              >Batalkan</v-btn
            >
            <v-btn
              depressed
              color="primary"
              class="text-capitalize"
              style="
                width: 179px;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0px;
                border-radius: 8px;
              "
              @click="actionPostWithdrawItemHistory"
              :loading="isLoadingPostHistory"
              :disabled="
                payloadHistory.withdrawAt == null ||
                payloadHistory.amountWithdraw < 1
              "
              >Simpan</v-btn
            >
          </section>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import CurrencyInput from "./currencyInput.vue";

export default {
  components: {
    CurrencyInput,
  },
  props: {
    item: {
      type: Object,
    },
  },
  watch: {
    //   item: function (newVal) {
    //     const collect = newVal.amountCollected;
    //     const target = newVal.targetAmount;
    //     const percent = (collect / target) * 100;
    //     const round = Math.round(percent);
    //     this.percentAmountCollected = round;
    //   },
    isDialogPostHistory() {
      this.payloadHistory = {
        postDonationId: this.$route.params.id,
        amountWithdraw: 1,
        withdrawAt: null,
      };
    },
  },
  data() {
    return {
      value: 0,
      percentAmountCollected: 0,
      items: [],
      history: [],
      isLoadingItems: false,
      isLoadingHistory: false,
      isLoadingPostHistory: false,
      isDialogPostHistory: false,
      isSuccessPostHistory: false,
      payloadHistory: {
        postDonationId: this.$route.params.id,
        amountWithdraw: 1,
        withdrawAt: null,
      },
    };
  },
  mounted() {
    this.handleDonationItems();
    this.handleWithdrawItemHistory();
  },
  computed: {
    minDate() {
      const currentDate = moment(this.item.createAt).format("YYYY-MM-DD");
      return currentDate;
    },
    maxDate() {
      const currentDate = moment().format("YYYY-MM-DD");
      return currentDate;
    },
  },
  methods: {
    ...mapActions({
      getDonationItems: "donation/getDonationItems",
      getWithdrawItemHistory: "donation/getWithdrawItemHistory",
      postWithdrawItemHistory: "donation/postWithdrawItemHistory",
    }),
    getEpoch(value) {
      this.payloadHistory.withdrawAt = moment(value)
        .subtract(7, "hours")
        .valueOf();
    },
    formated(epoch) {
      const humanDate = moment(epoch).format("DD/MM/YYYY");
      return humanDate;
    },
    handleDonationItems() {
      const id = this.$route.params.id;
      this.isLoadingItems = true;
      return this.getDonationItems(id)
        .then((response) => {
          this.isLoadingItems = false;
          this.items = response;
        })
        .catch((err) => {
          this.isLoadingItems = false;
          console.log("err items - ", { err });
        });
    },
    handleWithdrawItemHistory() {
      const id = this.$route.params.id;
      this.isLoadingHistory = true;
      return this.getWithdrawItemHistory(id)
        .then((response) => {
          this.isLoadingHistory = false;
          this.history = response;
        })
        .catch((err) => {
          this.isLoadingHistory = false;
        });
    },
    actionPostWithdrawItemHistory() {
      this.isLoadingPostHistory = true;
      return this.postWithdrawItemHistory(this.payloadHistory)
        .then(() => {
          this.isSuccessPostHistory = true;
          this.isLoadingPostHistory = false;
          setTimeout(() => {
            this.isSuccessPostHistory = false;
            this.isDialogPostHistory = false;
            this.handleDonationItems();
            this.handleWithdrawItemHistory();
            this.$emit("updateAmountItems");
          }, 1000);
        })
        .catch((err) => {
          this.isLoadingPostHistory = false;
          console.log("withdraw history - ", { err });
        });
    },
  },
};
</script>

<style lang="scss" module="d" src="../donation.scss"></style>
